import Inputmask from 'inputmask';

var selector = $('.numero-da-carteira');
var im = Inputmask({'mask': '9 999 999999999999 9', removeMaskOnSubmit: true, autoUnmask: true, autoGroup: true, groupSeparator: ' ', groupSize: 17}).mask(selector);

if(selector.val() !== ''){
  im = Inputmask({'mask': '99999999999999999', removeMaskOnSubmit: true}).mask(selector);
  im.mask(selector);
}
else{
  im.mask(selector);
}
